// Configure your import map in config/importmap.rb. Read more: https://github.com/rails/importmap-rails

//= require jquery3
//= require popper
//= require bootstrap
//= require rails-ujs

import { Turbo } from "@hotwired/turbo-rails"
Turbo.session.drive = false

//import "controllers"
import "trix"

import "@rails/actiontext"

import TomSelect from "tom-select"
//divなのをpタグに
Trix.config.blockAttributes.default = {
  tagName: "p",
  breakOnReturn: true
}

Trix.config.blockAttributes.heading1 = {
  tagName: "h2"
}
console.log(Trix.config)
/* what the newly created button does */
Trix.config.textAttributes.red = {
  style: { color: "red" },
  parser: function(element) {
      return element.style.color === "red"
  },
  inheritable: true
}

Trix.config.textAttributes.annotation = {
  class: { color: "red" },
  parser: function(element) {
      return element.class === "red"
    },
    inheritable: true
  }

  /* insert the button visual in the default toolbar */
addEventListener("trix-initialize", function(event) {
  var buttonHTML = '<button type="button" data-trix-attribute="red">RED</button>'

  event.target.toolbarElement.
  querySelector(".trix-button-group").
  insertAdjacentHTML("beforeend", buttonHTML)
})

if(document.getElementById("tag_id") != null){
  new TomSelect('#tag_id', {
  });
};

new TomSelect('#archive_related_tag_id', {
});

new TomSelect('#archive_tag_ids', {
  plugins: ["remove_button"],
  create: function(input, callback) {
        const data = { name: input }
        const token = document.querySelector('meta[name="csrf-token"]').content
        fetch('/admins/tags', {
          method: 'POST',
          headers:  {
            "X-CSRF-Token": token,
            "Content-Type": "application/json",
            "Accept": "application/json"
          },
          body: JSON.stringify(data)
        })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          callback({value: data.id, text: data.name })
        })
        .catch(error => {
         console.log("タグに使用できない記号が含まれています");
        });
      },
});


window.onload = function() {
  if (document.getElementById("archive_content")) { setTimeout(setLinkButtonEventListener, 1500) }
}

function setLinkButtonEventListener() {
  const button = fetchLinkButtonObject()
  if (!button) { return }

  button.addEventListener("click", async e => {
    e.preventDefault

    const urlInput = fetchUrlInputObject()
    const url = urlInput ? urlInput.value : ""
    if (url) {
      const response = await fetchEmbbedLink(url);
      if (response) { insertAttachment(response) }
    }
  })
}

function fetchLinkButtonObject() {
  return Array.from(document.getElementsByClassName("trix-button--dialog")).find(
    function( element ) {
      return element.value === "Link";
    }
  )
}

function fetchUrlInputObject() {
  return Array.from(document.getElementsByClassName("trix-input--dialog")).find(
    function( element ) {
      return element.placeholder === "Enter a URL…";
    }
  )
}

async function fetchEmbbedLink(url) {
  const apiPath = '/api/v1/oembed'
  const data = { url: url }

  try {
    const response = await fetch(apiPath, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
      },
      agentOptions: {
        rejectUnauthorized: false,
      },
      body: JSON.stringify(data)
    });
    if (!response.ok) {
      throw new Error(`Fetch Embed Link Error: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error(error.message);

    return null;
  }
}

function insertAttachment(data) {
  console.log(data)
  const trixElement = document.getElementById("archive_content");
  const editor = trixElement.editor

  const attachment = new Trix.Attachment({
    content: data.payload.oembed.html,
    sgid: data.payload.oembed.sgid
  });

  editor.insertAttachment(attachment)
}